import * as React from "react"
import { Grid, Typography } from "@material-ui/core"

const ContactGrid = () => {
  const one = 1
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      xs={12}
      md={12}
      style={{
        height: "100%",
        width: "100%",
        padding: "10px",
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        style={{ textAlign: "center", marginBottom: "20px" }}
      >
        <Typography variant="h4">Our Location</Typography>
      </Grid>
      <Grid
        xs={12}
        md={12}
        item
        style={{
          height: "100%",
          width: "90%",
          minHeight: "500px",
          border: "1px solid black",
          marginRight: "-1px",
        }}
      >
        <div
          className="google-map-code"
          style={{ height: "100%", width: "100%" }}
        >
          <iframe
            src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=bmc%20equipment+(Title)&amp;ie=UTF8&amp;t=&amp;z=15&amp;iwloc=B&amp;output=embed"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
        <br />
      </Grid>
    </Grid>
  )
}

export default ContactGrid
