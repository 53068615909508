import * as React from "react"
import {
  Box,
  Divider,
  Grid,
  Link,
  Typography,
  withStyles,
} from "@material-ui/core"
import { ContactBannerStyles } from "../styles/PartialStyles"
import PhoneIcon from "@material-ui/icons/Phone"
import EmailIcon from "@material-ui/icons/Email"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import FaxIcon from "@mui/icons-material/Fax"
import EmailForm from "./EmailForm"
import { ContactDetails } from "./partials"

type Props = {
  classes: {
    contactHeader: string
    container: string
    phone: string
    email: string
    fax: string
    emailForm: string
    divider: string
  }
}

const ContactBanner = ({ classes }: Props) => {
  return (
    <Box>
      <Grid
        container
        className={classes.container}
        spacing={2}
        justify="space-between"
      >
        <Grid
          item
          container
          xs={12}
          md={6}
          className={classes.emailForm}
          justify={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid item>
            <EmailForm />
          </Grid>
        </Grid>

        <Grid item container xs={12} md={6} spacing={0} justify={"center"}>
          <Grid
            item
            container
            xs={12}
            md={6}
            spacing={0}
            justify={"flex-start"}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              <Typography variant="h4">Our Details</Typography>
            </Grid>
            <ContactDetails />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withStyles(ContactBannerStyles)(ContactBanner)
//      <Grid
//           item
//           container
//           md={6}
//           xs={12}
//           className={classes.phone}
//           justify={"center"}
//           alignItems={"center"}
//           direction={"column"}
//         >
//           <Grid item>
//             <PhoneIcon />
//           </Grid>
//           <Grid item>
//             <Typography variant="body1">00 353 46 955 8122</Typography>
//           </Grid>
//         </Grid>
//         <Grid
//           item
//           container
//           md={6}
//           xs={12}
//           className={classes.email}
//           justify={"center"}
//           alignItems={"center"}
//           direction={"column"}
//         >
//           <Grid item>
//             <EmailIcon />
//           </Grid>
//           <Grid item>
//             <Typography variant="body1">info@bmcequip.com</Typography>
//           </Grid>
//         </Grid>
