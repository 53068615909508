import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import { Grid } from "@material-ui/core"
import ContactGrid from "../components/ContactGrid"
import ContactBanner, { ContactHeader } from "../components/ContactBanner"
import { PageHeader } from "../components/partials"

const ContactPage = () => (
  <Layout
    HeaderComponent={
      <PageHeader
        id={"contactHeader"}
        header={"Contact Us"}
        description={
          "If you have any queries, please don't hesitate to contact us!"
        }
      />
    }
  >
    <Seo title="Home" />
    <ContactBanner />
    <Grid container spacing={4}>
      <Grid
        item
        container
        xs={12}
        md={12}
        alignItems="center"
        justifyContent="center"
      >
        <ContactGrid />
      </Grid>
    </Grid>
  </Layout>
)

export default ContactPage
