import * as React from "react"
import { useEffect, useState } from "react"
import { Button, Grid, TextField, Typography } from "@material-ui/core"
import emailjs from "@emailjs/browser"

const EmailForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [formValid, setFormValid] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [emailSending, setEmailSending] = useState(false)
  const [emailCopied, setEmailCopied] = useState(false)

  const handleNameChange = event => {
    setName(event.target.value)
  }
  const handleEmailChange = event => {
    setEmail(event.target.value)
  }

  const handlePhoneChange = event => {
    setPhone(event.target.value)
  }
  const handleMessageChange = event => {
    setMessage(event.target.value)
  }

  const validateEmail = (email: string) => {
    const validator =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    return validator.test(email)
  }

  const emailButton = () => {
    if (formValid) {
      return !emailSending ? "Send Email" : "Sending...."
    } else {
      return "Please fill form!"
    }
  }

  useEffect(() => {
    if (
      !(name.trim() == "") &&
      !(phone.trim() == "") &&
      validateEmail(email.trim()) &&
      !(message.trim() == "")
    ) {
      setFormValid(true)
    } else {
      setFormValid(false)
    }
  }, [name, email, message])

  const sendEmail = () => {
    return emailjs.send(
      "service_n9amffo",
      "template_1x5dy9i",
      {
        from_name: name,
        from_email: email,
        from_message: message,
        from_phone: phone,
      },
      "K3j4GiHo17pPsN47O"
    )
  }

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Typography variant="h4">Email Us</Typography>
      </div>
      <TextField
        label="Name"
        placeholder="Your Name"
        fullWidth
        margin="normal"
        value={name}
        onChange={handleNameChange}
      />
      <TextField
        type="email"
        label="Email"
        placeholder="youremail@youremail.com"
        fullWidth
        margin="normal"
        value={email}
        onChange={handleEmailChange}
      />
      <TextField
        type="number"
        label="Phone"
        placeholder="08712345678"
        fullWidth
        margin="normal"
        value={phone}
        onChange={handlePhoneChange}
      />
      <TextField
        label="Message"
        placeholder="Your Message"
        fullWidth
        multiline
        margin="normal"
        value={message}
        onChange={handleMessageChange}
      />
      {!emailSent ? (
        <Button
          disabled={!formValid}
          color={"primary"}
          style={{ fontWeight: "bold" }}
          onClick={() => {
            if (!emailSending) {
              setEmailSending(true)
              sendEmail().then(() => setEmailSent(true))
            }
          }}
        >
          {emailButton()}
        </Button>
      ) : (
        <div>
          <Typography variant="h5">Submitted</Typography>
        </div>
      )}
    </div>
  )
}

export default EmailForm
